import styled from "styled-components";
import { IMusic } from "../../../../common/commonTypes";
import GlobalPlayerCard from "../../../../common/components/GlobalPlayerCard/GlobalPlayerCard";
import FadeImage from "../../../../common/components/FadeImage/FadeImage";
import vinyl from "../../../../../public/images/vinyl.png";
import { IMAGE_SIZES } from "../../../../constants/imageSizes";
import useMobileResponsive from "../../../../common/hooks/useMobileResponsive";

const Vinyl = styled.div`
  position: absolute;
  transition: all 0.5s ease-in-out;
  z-index: -1;
  height: 391px;
  width: 391px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 259px;
    width: 259px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    height: 200px;
    width: 200px;
  }
`;

const Track = styled.div`
  position: relative;
  max-width: 505px;
  width: 100%;
  z-index: 0;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.25));

  /* only play animation on desktop */
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &:hover {
      ${Vinyl} {
        transform: translateX(100px);
      }
    }
  }

  /* open by default on mobile */
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${Vinyl} {
      transform: translateX(50px);
    }
  }
`;

const StyledAudioPlayer = styled(GlobalPlayerCard)`
  width: 405px;
  border-radius: ${(props) => props.theme.borderRadius.md};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 259px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    width: 200px;
  }
`;

type Props = {
  music: IMusic;
  playlist?: IMusic[];
};

const CoverCard = ({ music, playlist }: Props) => {
  const { isMobile } = useMobileResponsive();
  const coverResizeWidth = isMobile
    ? IMAGE_SIZES.trackCoverArt.mediumSmall
    : IMAGE_SIZES.trackCoverArt.medium;

  return (
    <Track>
      <StyledAudioPlayer
        music={music}
        playlist={playlist}
        coverResizeWidth={coverResizeWidth}
      />
      <Vinyl>
        <FadeImage src={vinyl} layout="intrinsic" />
      </Vinyl>
    </Track>
  );
};

export default CoverCard;
