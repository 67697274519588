import styled from "styled-components";
import Button, { Label } from "./Button";

const ButtonLarge = styled(Button)`
  height: ${({ height }) => height || "60px"};

  ${Label} {
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.heading.xs};

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: ${({ theme }) => theme.paragraph.lg};
    }
  }
`;

export default ButtonLarge;
