import React from "react";
import styled from "styled-components";
import SignUpNowTextWeb from "./SignUpNowTextWeb";
import WillFarell from "./WillFarell";

const Container = styled.div`
  margin: 0 auto;
  display: grid;
  justify-content: center;
  justify-content: space-between;
  grid-template-columns: auto auto;
  padding-top: 222px;
`;

const SignUpNowMobile = () => {
  return (
    <Container className="main-container">
      <SignUpNowTextWeb />
      <WillFarell />
    </Container>
  );
};

export default SignUpNowMobile;
