import React from "react";
import styled from "styled-components";
import Promotion from "./Promotion/Promotion";
import MainSection from "./MainSection/MainSection";
import Banner from "./Banner/Banner";
import { useHomeQuery } from "../../queries/home.query";

const Root = styled.div`
  padding-bottom: 181px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-bottom: 59px;
  }
`;

const Home = () => {
  const { data, isLoading, refetch } = useHomeQuery();
  const { hots, drops, featured } = data || {};

  return (
    <Root>
      <Promotion />
      <Banner
        onRefresh={refetch}
        featuredItems={featured}
        isLoading={isLoading}
      />
      <MainSection drops={drops} hots={hots} isLoading={isLoading} />
    </Root>
  );
};

export default Home;
