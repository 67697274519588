import React from "react";
import styled from "styled-components";
import { IListing } from "../../../commonTypes";
import useBidCard from "../useBidCard";
import FeaturedBidTitle from "./FeaturedBidTitle";
import { ListingStatusEnum } from "../../../commonEnums";
import FeaturedActiveBidDetails from "./FeaturedActiveBidDetails";
import FeaturedSoldBidDetails from "./FeaturedSoldBidDetails";

const Root = styled.div`
  max-width: 540px;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 100%;
  }
`;

type Props = {
  listing: IListing;
  onClick: () => void;
};
const FeaturedBid = ({ listing, onClick }: Props) => {
  const { maxBid, price, priceUsd, currency, endTime } = useBidCard(listing);
  return (
    <Root>
      <FeaturedBidTitle featured={listing} />
      {listing.status === ListingStatusEnum.minted ? (
        <FeaturedSoldBidDetails
          bid={maxBid}
          price={price}
          priceUsd={priceUsd}
          currency={currency}
          endTime={endTime}
          type={listing.type}
        />
      ) : (
        <FeaturedActiveBidDetails
          bid={maxBid}
          price={price}
          priceUsd={priceUsd}
          asset={listing.asset}
          endTime={endTime}
          onClick={onClick}
          type={listing.type}
        />
      )}
    </Root>
  );
};

export default FeaturedBid;
