import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AboutModal from "../../../common/components/Modal/About/AboutModal";
import {
  getParagraph16BoldTypography,
  getParagraph18BoldTypography,
} from "../../../common/Typography";

const MainContainer = styled.div`
  min-height: 120px;
  background: linear-gradient(89.36deg, #f73859 -0.92%, #6e5cff 97.96%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.div`
  ${getParagraph18BoldTypography};
  font-size: 19px;
  color: ${({ theme }) => theme.primaryColor};
  text-align: center;
  line-height: 153.4%;
  padding: 0 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${getParagraph16BoldTypography};
    padding: 20px 52px;
    line-height: 137.4%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileM}) {
    padding: 20px 32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileS}) {
    padding: 20px 22px;
  }
`;

const MainText = styled.p`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.heading.xs};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.paragraph.lg};
  }
`;

const SubText = styled.p`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.paragraph.md};
  line-height: 1.2;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.paragraph.sm};
  }
`;

const ReadMoreText = styled.p`
  margin-top: 11px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.paragraph.md};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.paragraph.sm};
  }
`;

const Promotion = () => {
  const { t } = useTranslation();

  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const toggleAboutModal = () => setIsAboutModalOpen((value) => !value);

  return (
    <>
      <MainContainer>
        <TextContainer>
          <MainText>{t("promotion-banner-title-text")}</MainText>
          <SubText>{t("promotion-banner-subtitle-text")} </SubText>
          <ReadMoreText onClick={toggleAboutModal}>
            {t("promotion-banner-readmore-text")}
          </ReadMoreText>
        </TextContainer>
      </MainContainer>
      <AboutModal isOpen={isAboutModalOpen} onClose={toggleAboutModal} />
    </>
  );
};

export default Promotion;
