import React from "react";
import styled from "styled-components";
import { BidTypeEnum } from "../../../commonEnums";
import {
  getHeading6RegularTypography,
  getParagraph14BoldTypography,
  getParagraph14RegularTypography,
} from "../../../Typography";
import BidDetailsBadge from "../../Badges/BidDetailsBadge";

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

const Bid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BidLabel = styled.p`
  ${getHeading6RegularTypography};
  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    ${getParagraph14RegularTypography};
  }
`;

const Price = styled.p`
  ${getParagraph14BoldTypography}
`;

type Props = {
  title: string;
  price: string;
  priceUsd: string;
  bid: any;
  currency: string;
  type: BidTypeEnum;
};

const FeaturedBidPricePanel = ({
  title,
  price,
  priceUsd,
  bid,
  currency,
  type,
}: Props) => {
  return (
    <Root>
      <Bid>
        <BidLabel>{title}</BidLabel>
        <Price>${priceUsd}</Price>
      </Bid>
      <BidDetailsBadge
        bid={bid}
        price={price}
        currency={currency}
        type={type}
      />
    </Root>
  );
};

export default FeaturedBidPricePanel;
