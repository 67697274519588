import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BidTypeEnum } from "../../commonEnums";
import { IBid } from "../../commonTypes";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import {
  getHeading4BoldTypography,
  getHeading6BoldTypography,
  getParagraph14BoldTypography,
} from "../../Typography";
import Avatar from "../Avatar";
import Typography from "../Typography/Typography";
import StaticLink from "../StaticLink/StaticLink";
import { routes } from "../../../routes";
import { truncate } from "../../styles";

const Bidder = styled.div`
  display: flex;
  width: 100%;
  margin-top: 11px;
  align-items: center;
`;

const BidderDetails = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
`;

const CurrentBidEth = styled.h4`
  ${getHeading4BoldTypography};
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    ${getHeading6BoldTypography};
  }
`;

const BidderName = styled(StaticLink)`
  padding: 0 12px;
  ${getHeading6BoldTypography};
  color: ${(props) => props.theme.primaryColor};
  ${truncate};
  width: auto;
  flex: 1;

  &:before {
    content: "@";
  }

  &:empty {
    height: ${(props) => props.theme.paragraph.lg};
    width: 150px;
    margin-left: 12px;
    background: ${(props) => props.theme.lightBackgroundColor};
    border-radius: ${(props) => props.theme.borderRadius.md};

    &:before {
      content: "";
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    ${getParagraph14BoldTypography};
    line-height: 19px;
  }
`;

type Props = {
  bid?: IBid;
  price: any;
  currency: string;
  type: BidTypeEnum;
};

const BidDetailsBadge = ({ bid, price, currency, type }: Props) => {
  const { isMobile } = useMobileResponsive();
  const { t } = useTranslation();

  const styleWeb = {
    height: "40px",
    width: "40px",
  };

  const styleMobile = {
    height: "27px",
    width: "27px",
  };

  if (bid?.user === undefined) {
    return (
      <Bidder>
        {type === BidTypeEnum.bid && (
          <BidderDetails>
            <Typography size="lg" weight="bold">
              {t("no-bids-yet")}
            </Typography>
          </BidderDetails>
        )}
        <CurrentBidEth>
          {price} {currency}
        </CurrentBidEth>
      </Bidder>
    );
  }

  return (
    <Bidder>
      <BidderDetails>
        <StaticLink href={routes.user.url(bid.user.uri)}>
          <Avatar user={bid.user} style={isMobile ? styleMobile : styleWeb} />
        </StaticLink>
        <BidderName href={routes.user.url(bid.user.uri)}>
          {bid.user.username}
        </BidderName>
      </BidderDetails>
      <CurrentBidEth>
        {price} {currency}
      </CurrentBidEth>
    </Bidder>
  );
};

export default BidDetailsBadge;
