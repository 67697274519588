import React from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../routes";
import ButtonLarge from "../../../../common/components/Buttons/ButtonLarge";
import { Paragraph14Bold } from "../../../../common/Typography/Paragraph14";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const Description = styled(Paragraph14Bold)`
  color: rgba(255, 255, 255, 0.85);
  padding-bottom: 30px;
  text-transform: uppercase;
  max-width: 299px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SignUpNowTextMobile = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleOnClick = () => {
    router.push(routes.auth.join.root);
  };

  return (
    <Container>
      <Description>
        {t("home-main-section-sign-up-now-description")}
      </Description>
      <ButtonContainer>
        <ButtonLarge
          text={t("home-main-section-signup-now-button")}
          iconName="arrowRight"
          width="307px"
          height="50px"
          iconSize="18px"
          onClick={handleOnClick}
        />
      </ButtonContainer>
    </Container>
  );
};

export default SignUpNowTextMobile;
