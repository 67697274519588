import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import styled from "styled-components";
import background from "../../../../assets/home-default-banner.svg";
import { routes } from "../../../../routes";
import { Heading6Bold } from "../../../../common/Typography/Heading6";
import { Heading3Bold } from "../../../../common/Typography/Heading3";

const Root = styled.div<{ isFallback?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.isFallback ? "683px" : "100%")};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    margin-top: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background: url(${background});
    background-size: cover;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

const TitleContainer = styled.div`
  padding-bottom: 41px;
  margin: auto 0;
  margin-bottom: 0;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    padding-bottom: 63px;
  }
`;

const Title = styled(Heading3Bold)`
  line-height: 1.6;
  text-transform: uppercase;
  white-space: pre-wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.heading.sm};
    line-height: 1.4;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding-bottom: 137px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    padding-bottom: 165px;
  }
`;

const StyledButton = styled.button`
  height: 60px;
  width: 215px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border: 2px solid rgba(255, 255, 255);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    height: 50px;
    width: 169px;
  }
`;

const StyledButtonLabel = styled(Heading6Bold)`
  line-height: 18px;
`;

type Props = {
  isFallback?: boolean;
};

const DefaultBanner = ({ isFallback = false }: Props) => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleOnClick = () => {
    router.push(routes.marketplace.root);
  };

  return (
    <Root isFallback={isFallback}>
      <Container className="main-container">
        <TitleContainer>
          <Title>{t("home-banner-title")}</Title>
        </TitleContainer>
        <ButtonContainer>
          <StyledButton onClick={handleOnClick}>
            <StyledButtonLabel>
              {t("home-banner-shop-button")}
            </StyledButtonLabel>
          </StyledButton>
        </ButtonContainer>
      </Container>
    </Root>
  );
};

export default DefaultBanner;
