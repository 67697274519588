import type { NextPage } from "next";
import { QueryClient, dehydrate } from "react-query";
import Home from "../modules/Home/Home";
import { QueryKeys } from "../common/queryKeys";
import { fetchHomeData } from "../queries/home.query";
import { getPrefetchQueryWrapper } from "../helpers/nextHelper";

const HomePage: NextPage = () => <Home />;

HomePage.getInitialProps = getPrefetchQueryWrapper(async () => {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery(QueryKeys.homeData, () =>
    fetchHomeData({ hots: 3, drops: 3 })
  );

  return {
    dehydratedState: dehydrate(queryClient),
  };
});

export default HomePage;
