import React, { ReactNode } from "react";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: ${({ theme }) => theme.cardBackgroundTransparent};
  backdrop-filter: ${({ theme }) => theme.filterBlur};
  padding: 30px;
  flex-direction: column;
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.darkBorderColor};
  width: 100%;
  height: 1px;

  margin: 30px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 20px 0;
  }
`;

interface IProps {
  top: ReactNode;
  bottom: ReactNode;
}

const FeaturedBidCard = ({ top, bottom }: IProps) => {
  return (
    <Root>
      {top}
      <Divider />
      {bottom}
    </Root>
  );
};

export default FeaturedBidCard;
