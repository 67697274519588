import React from "react";
import Image from "next/image";
import styled from "styled-components";
import willFarell from "../../../../../public/images/will-farell.gif";

const Container = styled.div`
  margin: 0 auto;
  position: relative;
`;

const ImageWrapper = styled.div<{ isMobile?: boolean }>`
  border-radius: 22px;
  overflow: hidden;
  margin-left: ${(props) => (!props.isMobile ? "46px" : "0")};
  max-width: ${(props) => (props.isMobile ? "285px" : "436px")};
  max-height: ${(props) => (props.isMobile ? "285px" : "436px")};
`;

interface IWillFarrelProps {
  isMobile?: boolean;
}

const WillFarell = ({ isMobile }: IWillFarrelProps) => {
  return (
    <Container>
      <ImageWrapper isMobile={isMobile}>
        <Image alt="will farell" src={willFarell} loading="eager" />
      </ImageWrapper>
    </Container>
  );
};

export default WillFarell;
