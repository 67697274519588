import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FeaturedBidPricePanel from "./FeaturedBidPricePanel";
import { IBid } from "../../../commonTypes";
import { BidTypeEnum } from "../../../commonEnums";
import Typography from "../../Typography/Typography";
import { formatDate } from "../../../../helpers/dateTime";
import { getVerticalGap } from "../../../styles";
import FeaturedBidCard from "./FeaturedBidCard";

const AuctionDetailsContainer = styled.div`
  ${getVerticalGap("10px")};
`;

type Props = {
  bid?: IBid;
  price: string;
  priceUsd: string;
  currency: string;
  endTime: string;
  type: BidTypeEnum;
};

const FeaturedSoldBidDetails = ({
  bid,
  price,
  priceUsd,
  currency,
  endTime,
  type,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FeaturedBidCard
      top={
        <FeaturedBidPricePanel
          title={t("listing-sold")}
          price={price}
          priceUsd={priceUsd?.toLocaleString()}
          bid={bid}
          currency={currency}
          type={type}
        />
      }
      bottom={
        <AuctionDetailsContainer>
          <Typography size="xl">Sold date</Typography>
          <Typography heading weight="500">
            {formatDate(endTime)}
          </Typography>
        </AuctionDetailsContainer>
      }
    />
  );
};

export default FeaturedSoldBidDetails;
