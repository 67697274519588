import { useState } from "react";
import styled from "styled-components";
import ShareModal from "../../Modal/ShareModal";
import { routes } from "../../../../routes";
import { IListing } from "../../../commonTypes";
import Icon from "../../Icon/Icon";
import { safe } from "../../../../helpers/safeWebApis";

const ShareBadge = styled.button`
  display: flex;
  background: ${({ theme }) => theme.cardBackgroundTransparent};
  width: 50px;
  height: 50px;
  align-items: center;
  justify-items: center;
  backdrop-filter: ${({ theme }) => theme.filterBlur};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

const ShareIcon = styled(Icon)`
  margin: auto;
`;

type Props = {
  listing: IListing;
};

const Share = ({ listing }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onModalClose = () => {
    setIsOpen(false);
  };

  const onShareClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <ShareBadge onClick={onShareClick}>
        <ShareIcon name="share" />
      </ShareBadge>
      <ShareModal
        isOpen={isOpen}
        onClose={onModalClose}
        url={`${safe.window?.location.origin}${routes.marketplace.id(
          listing?.music_id,
          listing?.id
        )}`}
        image={listing?.music?.cover}
      />
    </>
  );
};

export default Share;
