import styled from "styled-components";
import { IListing } from "../../../common/commonTypes";
import NewDrops from "./NewDrops/NewDrops";
import SignUpNow from "./SignUp/SignUpNow";
import WhatsHot from "./WhatsHot/WhatsHot";
import { useSession } from "../../../queries/authentication.query";

const Root = styled.div`
  background: ${({ theme }) => theme.secondaryColor};
`;

type Props = {
  drops?: IListing[];
  hots?: IListing[];
  isLoading: boolean;
};

const MainSection = ({ drops = [], hots = [], isLoading }: Props) => {
  const { isLoggedIn, isFetched } = useSession();

  return (
    <Root>
      <NewDrops isLoading={isLoading} items={drops} />
      <WhatsHot isLoading={isLoading} items={hots} />
      {isFetched && !isLoggedIn && <SignUpNow />}
    </Root>
  );
};

export default MainSection;
