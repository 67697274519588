import styled from "styled-components";
import {
  getParagraph16BoldTypography,
  getParagraph14BoldTypography,
} from "../../../Typography";
import UserAvatar from "../../UserAvatar";
import { useRouter } from "next/router";
import { routes } from "../../../../routes";
import { IListing } from "../../../commonTypes";
import Typography from "../../Typography/Typography";

const ArtistNameBadge = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  background: ${({ theme }) => theme.cardBackgroundTransparent};
  width: fit-content;
  align-items: center;
  padding: 5px;
  padding-right: 15px;
  backdrop-filter: ${({ theme }) => theme.filterBlur};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  z-index: 1;
`;

const ArtistName = styled(Typography)`
  ${getParagraph16BoldTypography};
  cursor: pointer;

  &:before {
    content: "@";
  }

  &:empty {
    width: 200px;
    height: ${(props) => props.theme.paragraph.lg};
    background: ${(props) => props.theme.lightBackgroundColor};
    border-radius: ${(props) => props.theme.borderRadius.md};

    &:before {
      content: "";
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    ${getParagraph14BoldTypography};

    &:empty {
      width: 100px;
    }
  }
`;

type Props = {
  featured: IListing;
};

const ArtistBadge = ({ featured }: Props) => {
  const router = useRouter();
  const artist = featured?.artist;

  const handleArtistClick = () => {
    router.push(routes.user.url(artist?.uri));
  };

  return (
    <ArtistNameBadge>
      <UserAvatar user={artist} />
      <ArtistName onClick={handleArtistClick} truncate>
        {artist?.username}
      </ArtistName>
    </ArtistNameBadge>
  );
};

export default ArtistBadge;
