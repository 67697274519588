import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IListing } from "../../../../common/commonTypes";
import NftCard from "../../../../common/components/NftCard/NftCard";
import { routes } from "../../../../routes";
import Carousel from "../../../../common/components/Carousel/Carousel";
import ViewMoreButton from "../../../../common/components/Carousel/ViewMoreButton";
import SeeAllCard from "../../../../common/components/Carousel/SeeAllCard";

const Root = styled.div`
  padding-top: 87px;
`;

const Container = styled.div`
  margin: 0 auto;
`;

type Props = {
  items: IListing[];
  isLoading: boolean;
};

const WhatsHot = ({ items, isLoading }: Props) => {
  const { t } = useTranslation();
  const playlist = items.map(({ music }) => music);

  const marketRedirectUrl = routes.marketplace.root + "?category=hot";

  return (
    <Root>
      <Container className="main-container">
        <Carousel
          title={t("whats-hot-title")}
          items={items}
          renderItem={(listing) => (
            <NftCard
              music={listing.music}
              playlist={playlist}
              listing={listing}
              redirectUrl={routes.marketplace.id(listing.music_id!, listing.id)}
            />
          )}
          keyExtractor={(listing) => listing.id}
          isLoading={isLoading}
          autoplay
          redirectUrl={marketRedirectUrl}
          seeAllComponent={
            <SeeAllCard
              to={marketRedirectUrl}
              label={t("whats-currently-trending")}
            />
          }
        />
        {items.length > 0 && (
          <ViewMoreButton
            title={t("main-section-view-more-button")}
            redirectUrl={marketRedirectUrl}
          />
        )}
      </Container>
    </Root>
  );
};

export default WhatsHot;
