import React from "react";
import styled, { css } from "styled-components";
import CoverCard from "./CoverCard";
import { IListing, IMusic } from "../../../../common/commonTypes";
import FeaturedBid from "../../../../common/components/BidPanels/Featured/FeaturedBid";
import { getResizedUrl } from "../../../../helpers/imageHelper";
import { IMAGE_SIZES } from "../../../../constants/imageSizes";
import { getAbsoluteFill } from "../../../../common/styles";
import { useIsClientRender } from "../../../../common/hooks/useIsClientRender";
import { isVideo } from "../../../../helpers/fileHelper";
import { getResizedPosterUrl } from "../../../../helpers/videoHelper";

const Root = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    margin-top: 0;
  }
`;

const Background = styled.div<{
  $showBackground: boolean;
  $backgroundImage: string;
}>`
  background-color: ${({ theme }) => theme.backgroundColor}CC;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  background-blend-mode: overlay;
  ${getAbsoluteFill};
  filter: blur(40px);
  transform: scale(1.1);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }

  ${({ $showBackground, $backgroundImage }) =>
    $showBackground &&
    css`
      background-image: url(${$backgroundImage});
    `};
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1150px;
  color: ${({ theme }) => theme.primaryColor};
  display: flex;
  justify-content: space-between;
  padding: 86px 20px 192px 20px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 100%;
    padding-top: 40px;
    flex-direction: column;
    justify-content: unset;
    padding-bottom: 52px;
  }
`;

const TrackContent = styled.div`
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 21px;
  }
`;

interface IProps {
  listing: IListing;
  onPlaceBidClick: () => void;
  playlist?: IMusic[];
}

const TrackBanner = ({ listing, onPlaceBidClick, playlist }: IProps) => {
  const isClientRender = useIsClientRender();

  const getBackgroundUrl = () => {
    const { cover } = listing.music;

    if (isVideo(cover.mime)) {
      return getResizedPosterUrl(cover.url, IMAGE_SIZES.trackCoverArt.medium);
    }

    return getResizedUrl(cover.url, IMAGE_SIZES.trackCoverArt.medium);
  };

  return (
    <Root>
      <Background
        $showBackground={isClientRender}
        $backgroundImage={getBackgroundUrl()}
      />
      <Container>
        <CoverCard music={listing?.music} playlist={playlist} />
        <TrackContent>
          <FeaturedBid listing={listing} onClick={onPlaceBidClick} />
        </TrackContent>
      </Container>
    </Root>
  );
};

export default TrackBanner;
