import { IHomeData, IListing, IQueryParams } from "../common/commonTypes";
import { getPriorDate } from "../helpers/dateTime";
import { ListingStatusEnum } from "../common/commonEnums";
import { listingService } from "../api/listingService";
import { settings } from "../settings";
import { useQuery } from "react-query";
import { QueryKeys } from "../common/queryKeys";

const fetchDrops = async (limit: number = 9): Promise<IListing[]> => {
  const params: IQueryParams = {
    limit,
    where: {
      created: { $gte: getPriorDate(30) },
      "$artist.approved_artist$": { $eq: true },
      status: ListingStatusEnum.active,
    },
    order: [["created", "DESC"]],
  };
  const result = await listingService.fetchListings(params);
  return result.data.items;
};

const fetchHots = async (limit: number = 9): Promise<IListing[]> => {
  const params: IQueryParams = {
    limit,
    where: {
      created: { $gte: getPriorDate(90) },
      "$artist.approved_artist$": { $eq: true },
      status: ListingStatusEnum.active,
    },
    order: [["views", "DESC"]],
  };

  const result = await listingService.fetchListings(params);
  return result.data.items;
};

const fetchFeatured = async (
  limit: number = settings.FEATURED_ITEMS_COUNT
): Promise<IListing[]> => {
  let featured: IListing[];
  let params: IQueryParams = {
    limit,
    where: {
      featured: true,
      status: {
        $in: [ListingStatusEnum.active, ListingStatusEnum.minted],
      },
      "$artist.approved_artist$": { $eq: true },
    },
    order: [["updated", "DESC"]],
  };
  const result = await listingService.fetchListings(params);
  featured = result.data.items || [];

  if (featured.length === 0) {
    params = {
      limit: 1,
      where: {
        created: { $gte: getPriorDate(30) },
        status: {
          $in: [ListingStatusEnum.active, ListingStatusEnum.minted],
        },
        "$artist.approved_artist$": { $eq: true },
      },
      order: [["updated", "DESC"]],
    };
    const resultActiveListing = await listingService.fetchListings(params);
    featured = resultActiveListing.data.items || [];
  }

  return featured;
};

interface IHomePageDataLimits {
  drops?: number;
  hots?: number;
  featured?: number;
}

export const fetchHomeData = async (
  limits: IHomePageDataLimits = {}
): Promise<IHomeData> => {
  const [hots, drops, featured] = await Promise.all([
    fetchHots(limits.hots),
    fetchDrops(limits.drops),
    fetchFeatured(limits.featured),
  ]);

  return {
    hots,
    drops,
    featured,
  };
};

export const useHomeQuery = () => {
  return useQuery<IHomeData>(QueryKeys.homeData, () => fetchHomeData());
};
