import React from "react";
import styled from "styled-components";
import { routes } from "../../../../routes";
import {
  getHeading1BoldTypography,
  getHeading4BoldTypography,
} from "../../../Typography";
import ArtistBadge from "./ArtistBadge";
import Share from "./Share";
import { IListing } from "../../../commonTypes";
import StaticLink from "../../StaticLink/StaticLink";

const Root = styled.div``;

const Title = styled(StaticLink)`
  ${getHeading1BoldTypography};
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
  text-decoration: none;

  &:empty {
    width: 300px;
    height: ${(props) => props.theme.heading.xxl};
    background: ${(props) => props.theme.lightBackgroundColor};
    border-radius: ${(props) => props.theme.borderRadius.md};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${getHeading4BoldTypography};
  }
`;

const TrackBadges = styled.div`
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;

  *:not(:last-child) {
    margin-right: 10px;
  }
`;

type Props = {
  featured: IListing;
};

const FeaturedBidTitle = ({ featured }: Props) => {
  return (
    <Root>
      <Title href={routes.marketplace.id(featured?.music_id, featured?.id)}>
        {featured?.music.title}
      </Title>
      <TrackBadges>
        <ArtistBadge featured={featured} />
        <Share listing={featured} />
      </TrackBadges>
    </Root>
  );
};

export default FeaturedBidTitle;
