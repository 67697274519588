import styled from "styled-components";
import { useRouter } from "next/router";
import ButtonLarge from "../../../../common/components/Buttons/ButtonLarge";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../routes";
import { Heading6Bold } from "../../../../common/Typography/Heading6";
import { Heading2Bold } from "../../../../common/Typography/Heading2";

const Root = styled.div``;

const Container = styled.div`
  max-width: 452px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 130px;
`;

const Title = styled(Heading2Bold)`
  width: 100%;
  padding-bottom: 20px;
  text-transform: uppercase;
`;

const Description = styled(Heading6Bold)`
  color: rgba(255, 255, 255, 0.85);
  padding-bottom: 40px;
  text-transform: uppercase;
`;

const SignUpNowTextWeb = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleOnClick = () => {
    router.push(routes.auth.join.root);
  };

  return (
    <Root>
      <Container>
        <Title>{t("home-main-section-sign-up-now-title")}</Title>
        <Description>
          {t("home-main-section-sign-up-now-description")}
        </Description>
        <ButtonLarge
          text={t("landing-welcome-signup-now")}
          iconName="arrowRight"
          width="254px"
          iconSize="18px"
          onClick={handleOnClick}
        />
      </Container>
    </Root>
  );
};

export default SignUpNowTextWeb;
