import React from "react";
import styled, { useTheme } from "styled-components";
import SignUpNowMobile from "./SignUpNowMobile";
import SignUpNowWeb from "./SignUpNowWeb";
import { useWindowSize } from "../../../../context/WindowSizeContext";

const Root = styled.div`
  margin: 0 auto;
`;

const SignUpNow = () => {
  const size = useWindowSize();
  const theme = useTheme();
  const isMobile = size.width! <= parseInt(theme.breakpoints.tablet);

  return <Root>{isMobile ? <SignUpNowMobile /> : <SignUpNowWeb />}</Root>;
};

export default SignUpNow;
