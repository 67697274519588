import styled from "styled-components";

export const StyledBanner = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0),
      50%,
      rgb(99, 80, 250, 0.3)
    );
  }

  && {
    .swiper-container-banner {
      position: relative;
    }

    .bullet-container {
      display: flex;
      max-width: 1150px;
      margin: 0 auto;
      justify-content: center;
    }

    .swiper-pagination {
      bottom: 90px;

      @media (max-width: 768px) {
        bottom: 10px;
      }
    }

    .swiper-pagination-bullet {
      width: 60px;
      height: 6px;
      border-radius: 1px;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.4);
      margin: 0;

      &:not(:last-child) {
        margin-right: 5px;
      }

      @media (max-width: 425px) {
        width: 45px;
      }
    }

    .swiper-pagination-bullet-active {
      background: #fff;
    }

    .swiper-slide {
      height: unset;
      max-height: 900px;
    }
  }
`;
