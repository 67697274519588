import React, { useRef, useEffect, useMemo, useState } from "react";
import {
  Swiper,
  SwiperCore,
  SwiperSlide,
} from "../../../common/components/Swiper/Swiper";
import TrackBanner from "./Track/TrackBanner";
import DefaultBanner from "./Default/DefaultBanner";
import { IListing } from "../../../common/commonTypes";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import styled from "styled-components";
import { getFlexCenter } from "../../../common/styles";
import useMobileResponsive from "../../../common/hooks/useMobileResponsive";
import { useGlobalPlayer } from "../../../context/GlobalPlayerProvider";
import PlaceBidModal from "../../../common/components/Modal/PlaceBidModal";
import { StyledBanner } from "./Banner.styled";

type Props = {
  featuredItems?: IListing[];
  isLoading: boolean;
  onRefresh: () => void;
};

const LoadingContainer = styled.div`
  ${getFlexCenter};
  height: 683px;
`;

const Banner = ({ featuredItems = [], isLoading, onRefresh }: Props) => {
  const swiperRef = useRef<SwiperCore>();
  const isHovered = useRef(false);
  const { isTablet } = useMobileResponsive();
  const { track, isPlaying } = useGlobalPlayer();
  const playlist = featuredItems.map((item) => item.music);
  const [activeListing, setActiveListing] = useState<IListing | null>(null);

  const isBannerTrackPlaying = useMemo(() => {
    if (!isLoading && featuredItems.length > 0 && track && isPlaying) {
      return featuredItems.some((listing) => listing.music_id === track.id);
    }

    return false;
  }, [isLoading, featuredItems, track, isPlaying]);

  const startAutoplay = () => swiperRef.current?.autoplay.start();
  const stopAutoplay = () => swiperRef.current?.autoplay.stop();

  useEffect(() => {
    if (isBannerTrackPlaying) {
      stopAutoplay();
    } else {
      if (!isHovered.current) {
        startAutoplay();
      }
    }
  }, [isBannerTrackPlaying]);

  const onMouseOver = () => {
    // we need to use mouseover event instead of mouseenter because mouseenter is not fired when component mounts
    // under the cursor
    if (!isHovered.current) {
      isHovered.current = true;
      stopAutoplay();
    }
  };

  const onMouseLeave = () => {
    isHovered.current = false;
    if (!isBannerTrackPlaying) {
      startAutoplay();
    }
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (featuredItems.length === 0) {
    return <DefaultBanner isFallback={true} />;
  }

  return (
    <>
      <StyledBanner onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <Swiper
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          pagination={{ clickable: true }}
          slidesPerView={1}
          longSwipesRatio={0.2}
          speed={700}
          autoplay={{
            delay: 5000,
            disableOnInteraction: isTablet,
            pauseOnMouseEnter: false,
          }}
          className="swiper-container-banner"
          threshold={5}
        >
          {featuredItems.map((listing) => (
            <SwiperSlide key={listing.id}>
              <TrackBanner
                listing={listing}
                onPlaceBidClick={() => setActiveListing(listing)}
                playlist={playlist}
              />
            </SwiperSlide>
          ))}

          <SwiperSlide>
            <DefaultBanner />
          </SwiperSlide>
        </Swiper>
      </StyledBanner>
      {activeListing && (
        <PlaceBidModal
          isOpen
          onClose={() => setActiveListing(null)}
          listing={activeListing}
          onSuccess={onRefresh}
        />
      )}
    </>
  );
};

export default Banner;
