import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FeaturedBidPricePanel from "./FeaturedBidPricePanel";
import AuctionTimer from "../AuctionTimer";
import Button from "../../Buttons/Button";
import { IAsset, IBid } from "../../../commonTypes";
import { BidTypeEnum } from "../../../commonEnums";
import { useTimer } from "../../../hooks/useTimer";
import FeaturedBidCard from "./FeaturedBidCard";
import { shouldShowTimer } from "../../../../helpers/listingHelper";

const AuctionDetailsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
`;

type Props = {
  bid?: IBid;
  price: string;
  priceUsd: string;
  asset: IAsset;
  endTime: string;
  onClick: () => void;
  type: BidTypeEnum;
};

const FeaturedActiveBidDetails = ({
  bid,
  price,
  priceUsd,
  asset,
  endTime,
  onClick,
  type,
}: Props) => {
  const { t } = useTranslation();
  const isAuction = type === BidTypeEnum.bid;

  const strings = {
    cta: isAuction ? t("featured-track-place-a-bid") : t("purchase-now"),
    endingLabel: isAuction ? t("featured-track-aution-ends") : t("sale-ends"),
    endedLabel: isAuction ? t("auction-ended") : t("sale-ended"),
    title: isAuction ? t("featured-track-current-bid") : t("sale-price"),
  };

  const { label, onTimerCompleted, isComplete } = useTimer({
    inProgressLabel: strings.endingLabel,
    endedLabel: strings.endedLabel,
  });

  return (
    <FeaturedBidCard
      top={
        <FeaturedBidPricePanel
          title={strings.title}
          price={price}
          priceUsd={priceUsd?.toLocaleString()}
          bid={bid}
          currency={asset.symbol}
          type={type}
        />
      }
      bottom={
        <AuctionDetailsContainer>
          <Button text={strings.cta} onClick={onClick} disabled={isComplete} />
          {(type === BidTypeEnum.bid || shouldShowTimer(endTime)) && (
            <AuctionTimer
              title={label}
              titleMarginBottom={"10px"}
              endTime={endTime}
              onComplete={onTimerCompleted}
              showWhenEnded={false}
            />
          )}
        </AuctionDetailsContainer>
      }
    />
  );
};

export default FeaturedActiveBidDetails;
