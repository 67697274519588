import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SignUpNowTextMobile from "./SignUpNowTextMobile";
import WillFarell from "./WillFarell";
import { Heading5Bold } from "../../../../common/Typography/Heading5";

const Root = styled.div`
  margin: 0 auto;
  padding-top: 72px;
`;

const Container = styled.div`
  margin: 0 auto;
  display: grid;
  justify-content: center;
  row-gap: 20px;
`;

const TitleContainer = styled.div`
  margin: 0 auto;
`;

const Title = styled(Heading5Bold)`
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
`;

const SignUpNowMobile = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <TitleContainer>
        <Title>{t("home-main-section-sign-up-now-title")}</Title>
      </TitleContainer>
      <Container>
        <WillFarell isMobile={true} />
        <SignUpNowTextMobile />
      </Container>
    </Root>
  );
};

export default SignUpNowMobile;
