import styled from "styled-components";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import { Paragraph16Bold } from "../../Typography/Paragraph16";

const Container = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    display: none;
  }
`;

const StyledLabel = styled(Paragraph16Bold)`
  line-height: 18px;
`;

interface IViewMoreButtonProps {
  title: string;
  redirectUrl: string;
}

const ViewMoreButton = ({ title, redirectUrl }: IViewMoreButtonProps) => {
  return (
    <Container>
      <ButtonOutlined
        href={redirectUrl}
        height="50px"
        width="350px"
        label={<StyledLabel>{title}</StyledLabel>}
      />
    </Container>
  );
};

export default ViewMoreButton;
